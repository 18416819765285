import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, withKeys as _withKeys, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-366ce904"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "entity-details-top-artists" }
const _hoisted_2 = { class: "entity-details-top-artists-container is-flex is-flex-wrap-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Label = _resolveComponent("Label")!
  const _component_Block = _resolveComponent("Block")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Label, { size: "md" }, {
      title: _withCtx(() => [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t("entity.artists")), 1)
      ]),
      subtitle: _withCtx(() => []),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.artists, (artist) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: artist,
          to: { name: 'Search', query: { creatorsNames: artist } }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Block, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(artist), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128)),
      (_ctx.creatorsNames.length > _ctx.DISPLAY_AMOUNT)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "mt-1",
            tabindex: "0",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle())),
            onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.toggle()), ["enter"]))
          }, _toDisplayString(_ctx.showAll
                        ? _ctx.$t("entity.show-less")
                        : _ctx.$t("entity.and-more", { amount: _ctx.creatorsNames.length - _ctx.DISPLAY_AMOUNT })), 33))
        : _createCommentVNode("", true)
    ])
  ]))
}