import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a1be9ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "entity-details-top pt-6 pb-5 columns" }
const _hoisted_2 = { class: "column is-one-half-tablet is-two-third-desktop is-three-quarter-widescreen" }
const _hoisted_3 = { class: "entity-details-top-description p-5 mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslationAlt = _resolveComponent("TranslationAlt")!
  const _component_Label = _resolveComponent("Label")!
  const _component_Translation = _resolveComponent("Translation")!
  const _component_EntityDetailsTopArtists = _resolveComponent("EntityDetailsTopArtists")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Label, { size: "bg" }, {
        title: _withCtx(() => [
          _createElementVNode("h1", null, [
            _createVNode(_component_TranslationAlt, {
              data: _ctx.entity,
              property: "title"
            }, null, 8, ["data"])
          ])
        ]),
        subtitle: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Translation, {
          data: _ctx.entity,
          property: "description"
        }, null, 8, ["data"])
      ])
    ]),
    _createVNode(_component_EntityDetailsTopArtists, {
      class: "column is-one-half-tablet",
      creatorsNames: _ctx.creatorsNames
    }, null, 8, ["creatorsNames"])
  ]))
}