import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExhibitionBlockContent = _resolveComponent("ExhibitionBlockContent")!
  const _component_EntityDetails = _resolveComponent("EntityDetails")!

  return (_openBlock(), _createBlock(_component_EntityDetails, { entityName: "exhibitions" }, {
    default: _withCtx(({ entity }) => [
      _createVNode(_component_ExhibitionBlockContent, { entity: entity }, null, 8, ["entity"])
    ]),
    _: 1
  }))
}