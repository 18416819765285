
import {
    useSearch,
    useSingleResource,
    ArtworkDto,
    useDocumentTitle,
    TranslatableEntity,
    useFilterOptions,
} from "@kunsten/core";
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import SearchResults from "../search/shared/SearchResults.vue";
import SearchResultsCount from "../search/shared/SearchResultsCount.vue";
import SearchDisplayMode from "../search/shared/SearchDisplayMode.vue";
import SearchLoadMore from "../search/shared/SearchLoadMore.vue";
import EntityDetailsTop from "./EntityDetailsTop.vue";
import SpinnerContainer from "../../shared/components/SpinnerContainer.vue";

export default defineComponent({
    name: "EntityDetails",
    components: {
        SearchResults,
        SearchDisplayMode,
        SearchLoadMore,
        EntityDetailsTop,
        SearchResultsCount,
        SpinnerContainer,
    },
    props: {
        entityName: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const route = useRoute();
        const entityName = ref(props.entityName);
        const entityId = ref("");
        const creatorsNames = ref([] as string[]);
        const { locale, t } = useI18n();
        const displayMode = ref("four-column");
        const { setTitle } = useDocumentTitle(t("general.base-title"));

        watch(
            route,
            () => {
                entityId.value = route.params.id as string;
            },
            { deep: true, immediate: true }
        );

        const { entity, loading: entityLoading } = useSingleResource(entityName, entityId);
        const { getFilterOptions, loading: filtersLoading } = useFilterOptions(locale, false);
        const { results, filters, loading, loadMore, canLoadMore, totalItems } = useSearch({
            language: locale,
            itemsPerPage: 28,
            // stop initial search
            initialResults: {
                page: 1,
                results: [],
                totalItems: 0,
            },
        });

        watch(
            entity,
            () => {
                if (entity.value) {
                    const property = (locale.value + "Translation") as "daTranslation" | "enTranslation";
                    setTitle((entity.value as unknown as TranslatableEntity<{ title: string }>)[property]?.title);
                } else {
                    setTitle();
                }
                if (entity.value?.artworkSearchData) {
                    const params = JSON.parse(entity.value?.artworkSearchData as string);
                    Object.keys(params).forEach((key) => {
                        filters[key] = params[key];
                    });
                } else {
                    const iris =
                        (entity.value?.artworks as (string | ArtworkDto)[]).map((a) => {
                            if (typeof a === "string") return a;
                            return a["@id"];
                        }) || [];
                    const ids: string[] = iris
                        .map((id) => {
                            const parts = id.split("/");
                            if (parts.length === 4) return parts[3];
                            return "";
                        })
                        .filter((id) => !!id) as string[];
                    if (!ids.length) {
                        loading.value = false;
                        return;
                    }
                    filters["id"] = ids;
                }

                getFilterOptions(filters).then((res) => {
                    creatorsNames.value = res.creatorsNames.terms.map((creator) => creator.value);
                });
            },
            { deep: true }
        );

        return {
            entity,
            loading,
            entityLoading,
            results,
            displayMode,
            creatorsNames,
            loadMore,
            canLoadMore,
            totalItems,
            filtersLoading,
        };
    },
});
