import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2348bc04"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "entity-details page-container pb-6"
}
const _hoisted_2 = { class: "entity-details-artworks" }
const _hoisted_3 = { class: "is-flex is-justify-content-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityDetailsTop = _resolveComponent("EntityDetailsTop")!
  const _component_SearchResultsCount = _resolveComponent("SearchResultsCount")!
  const _component_SearchDisplayMode = _resolveComponent("SearchDisplayMode")!
  const _component_SearchResults = _resolveComponent("SearchResults")!
  const _component_SearchLoadMore = _resolveComponent("SearchLoadMore")!
  const _component_SpinnerContainer = _resolveComponent("SpinnerContainer")!

  return (!_ctx.entityLoading && !_ctx.filtersLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_EntityDetailsTop, {
          entity: _ctx.entity,
          creatorsNames: _ctx.creatorsNames
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", { entity: _ctx.entity }, undefined, true)
          ]),
          _: 3
        }, 8, ["entity", "creatorsNames"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_SearchResultsCount, { count: _ctx.totalItems }, null, 8, ["count"]),
            _createVNode(_component_SearchDisplayMode, {
              onChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.displayMode = $event)),
              displayMode: _ctx.displayMode
            }, null, 8, ["displayMode"])
          ]),
          _createVNode(_component_SearchResults, {
            results: _ctx.results,
            displayMode: _ctx.displayMode
          }, null, 8, ["results", "displayMode"]),
          _createVNode(_component_SearchLoadMore, {
            onLoad: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadMore())),
            loading: _ctx.loading,
            canLoadMore: _ctx.canLoadMore
          }, null, 8, ["loading", "canLoadMore"])
        ])
      ]))
    : (_openBlock(), _createBlock(_component_SpinnerContainer, { key: 1 }))
}