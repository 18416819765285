
import { defineComponent, PropType } from "vue";
import Label from "../../shared/components/Label.vue";
import Translation from "../../shared/components/Translation.vue";
import TranslationAlt from "../../shared/components/TranslationAlt.vue";
import EntityDetailsTopArtists from "./EntityDetailsTopArtists.vue";

export default defineComponent({
    name: "EntityDetailsTop",
    components: { Label, Translation, TranslationAlt, EntityDetailsTopArtists },
    props: {
        entity: {
            type: Object,
            required: true,
        },
        creatorsNames: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
    },
});
