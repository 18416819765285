
import { computed, defineComponent, PropType, ref } from "vue";
import Block from "../../shared/components/Block.vue";
import Label from "../../shared/components/Label.vue";

export default defineComponent({
    name: "EntityDetailsTopArtists",
    components: { Block, Label },
    props: {
        creatorsNames: {
            type: Array as PropType<string[]>,
            default: () => [],
        },
    },
    setup(props) {
        const DISPLAY_AMOUNT = ref(20);
        const showAll = ref(false);

        const artists = computed(() => {
            if (!showAll.value && props.creatorsNames.length > DISPLAY_AMOUNT.value) {
                return props.creatorsNames.slice(0, DISPLAY_AMOUNT.value);
            }
            return props.creatorsNames;
        });

        const toggle = () => {
            showAll.value = !showAll.value;
        };

        return { DISPLAY_AMOUNT, artists, showAll, toggle };
    },
});
